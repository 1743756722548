import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SearchModal from '../components/search-modal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Search: React.FC<any> = (): ReactElement => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      return <SearchModal categories={data.allStrapiCategories.nodes} chefs={data.allStrapiChefs.nodes}></SearchModal>;
    }}
  />
);

export default Search;

const indexQuery = graphql`
  query searchCategories {
    allStrapiCategories(sort: { fields: recipes, order: DESC }) {
      nodes {
        id
        name
        description
        fields {
          path
        }
        thumbnail {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    allStrapiChefs(sort: { fields: recipes, order: DESC }) {
      nodes {
        id
        alias
        instagram
        description
        avatar {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        fields {
          path
        }
      }
    }
  }
`;
