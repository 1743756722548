import React, { ReactElement, useState, useEffect, FormEvent } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SearchModalProps } from './search-modal.model';
import SearchModalStyles from './search-modal.module.scss';
import * as JsSearch from 'js-search';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SearchModal: React.FC<SearchModalProps> = (props: SearchModalProps): ReactElement => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    searchResults: [] as any[],
    recipesSearch: new JsSearch.Search(['node', 'title']),
  });

  const recipesData = useStaticQuery(graphql`
    query allAvailableRecipes {
      allStrapiRecipes {
        edges {
          node {
            fields {
              path
            }
            description
            title
            source {
              date(formatString: "DD, MMM y", locale: "es")
            }
          }
        }
      }
    }
  `);

  const searchInput = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    state.recipesSearch.tokenizer = new JsSearch.StopWordsTokenizer(new JsSearch.SimpleTokenizer());
    JsSearch.StopWordsMap.del = true;
    JsSearch.StopWordsMap.con = true;
    state.recipesSearch.addDocuments(recipesData.allStrapiRecipes.edges);
    state.recipesSearch.addIndex(['node', 'title']);
  }, []);

  const handleInputChange = (query: string) => {
    if (query.length > 2 || state.searchResults.length > 0) {
      setState(state => {
        return {
          ...state,
          searchResults: query.length > 2 ? state.recipesSearch.search(query) : [],
        };
      });
    }
  };
  return (
    <div
      className={`${SearchModalStyles.section_search} ${SearchModalStyles.flex} ${SearchModalStyles.is_load}`}
      id="search"
    >
      <div className={SearchModalStyles.search_close}>
        <a href="javascript: window.history.back()" aria-label={t('BACK')}>
          <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4285714,12 L24,20.5714286 L20.5714286,24 L12,15.4285714 L3.42857143,24 L3.55271368e-15,20.5714286 L8.57142857,12 L5.32907052e-15,3.42857143 L3.42857143,3.55271368e-15 L12,8.57142857 L20.5714286,3.55271368e-15 L24,3.42857143 L15.4285714,12 Z"></path>
          </svg>
        </a>
      </div>
      <div
        className={SearchModalStyles.search_image}
        style={{ backgroundImage: `url(${'/search-background.jpg'})` }}
      ></div>
      <div className={SearchModalStyles.search_wrap}>
        <div className={SearchModalStyles.search_content}>
          <form
            className={SearchModalStyles.flex}
            onSubmit={(event: FormEvent) => {
              event.preventDefault();
              searchInput.current?.blur();
            }}
          >
            <input
              type="text"
              ref={searchInput}
              className={SearchModalStyles.search_input}
              placeholder={t('SEARCH_PLACEHOLDER_TEXT')}
              aria-label={t('SEARCH_PLACEHOLDER_TEXT')}
              onChange={e => handleInputChange(e.target.value)}
            ></input>
          </form>
          <div className={SearchModalStyles.search_meta}>
            {state.searchResults.length == 0 && (
              <span className={SearchModalStyles.search_info_wrap}>{t('SEARCH_DESCRIPTION_TEXT')}</span>
            )}
            {state.searchResults.length > 0 && (
              <span className={SearchModalStyles.search_counter_wrap}>
                <span className={SearchModalStyles.counter_results}></span>
                {state.searchResults.length} {t('SEARCH_FOUND_RECIPES')}
              </span>
            )}
            <div className={SearchModalStyles.search_results}>
              {state.searchResults.length > 0 &&
                state.searchResults.map((result, key) => {
                  return (
                    <AniLink to={result.node.fields.path} key={key}>
                      <h4>
                        {result.node.title}
                        <span className={SearchModalStyles.search_date}>
                          {t('SEARCH_PUBLISHED')} — {result.node.source.date}
                        </span>
                      </h4>
                    </AniLink>
                  );
                })}
            </div>
          </div>
          <div className={SearchModalStyles.search_results}></div>
          <div className={`${SearchModalStyles.search_suggestion} ${SearchModalStyles.flex}`}>
            <div className={SearchModalStyles.search_suggestion_tags}>
              <h3>{t('SEARCH_TOP_CHEFS')}</h3>
              {props.chefs.slice(0, 8).map((chef, index) => {
                return (
                  <AniLink to={chef.fields.path} key={index} className="post-tag global-tag">
                    {chef.alias}
                  </AniLink>
                );
              })}
            </div>
            <div className={SearchModalStyles.search_suggestion_authors}>
              <h3>{t('SEARCH_TOP_CATEGORIES')}</h3>
              {props.categories.slice(0, 8).map((category, index) => {
                return (
                  <AniLink to={category.fields.path} key={index} className="post-tag global-tag">
                    {category.name}
                  </AniLink>
                );
              })}
            </div>
          </div>
        </div>
        <div className={SearchModalStyles.search_footer}>
          <AniLink fade duration={1.5} to={'/'}>
            SiChef!.{' '}
          </AniLink>
          <span>{t('FOOTER_SLOGAN_TITLE')}</span>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
